.date-picker {
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  max-width: 90%;
  font-size: 1rem !important;

  background-color: #edf2f7 !important;
  border: 0px solid #ced4da !important;
  border-radius: 5px;
  color: #a1aec0;
  /* margin-left:  10px !important; */
  width: 100% !important;

  /* margin-bottom: 20px; */
}

.date-picker-dark {
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  max-width: 90%;
  font-size: 1rem !important;

  /* background-color: #edf2f7 !important; */
  background-color: #232323 !important;
  border: 0px solid #ced4da !important;
  border-radius: 5px;
  color: whiteAlpha.600;
  /* margin-left:  10px !important; */
  width: 100% !important;

  /* margin-bottom: 20px; */
}

.date-picker:hover {
  background-color: #e2e8f0 !important;
  cursor: pointer !important;
}

.date-picker-dark:hover {
  background-color: #394354 !important;
  cursor: pointer !important;
}

.react-datepicker__triangle {
  /* left: 196px !important; */
}

.react-datepicker__close-icon::after {
  /* cursor: pointer !important; */
  background-color: transparent !important;
  color: hsl(0, 0%, 80%) !important;
  font-size: 24px !important;
  padding-right: 20px !important;
}

.react-datepicker__close-icon:hover {
  /* cursor: pointer !important; */
  /* background-color: #efefef !important; */
  color: #666666 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  /* display: block !important; */
  width: 100% !important;
}

.date-picker-wrapper-dark.react-datepicker {
  background-color: green !important;
  color: white !important;
}
/* .MyClass .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100% !important;
} */

.react-datepicker-popper {
  z-index: 100000000000000 !important;
}

.dark-picker .react-datepicker__month-container {
  background-color: #232323;
  border-color: #171923 !important;
  color: white !important;
}

.dark-picker .react-datepicker__header {
  background-color: #232323;
  border-color: #171923 !important;
}
.dark-picker .react-datepicker {
  border: 1px solid #171923 !important;
  color: white !important;
}

.dark-picker .react-datepicker__day {
  color: white !important;
}
.dark-picker .react-datepicker__day:hover {
  /* color: red !important; */
  background-color: #394354;
}
.dark-picker .react-datepicker__day--selected {
  color: white !important;
  background-color: #171923;
}

.dark-picker .react-datepicker__day-name {
  color: white !important;
}
.dark-picker .react-datepicker__current-month {
  color: white !important;
}

.dark-picker .react-datepicker__time-container .react-datepicker__time {
  background-color: #232323;
}

.dark-picker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #394354;
}

.dark-picker .react-datepicker-time__header {
  color: white;
}

.dark-picker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #171923;
}

/* .video-js .vjs-time-control {
  display: block;
}
.video-js .vjs-remaining-time {
  display: none;
} */

/* .video-js .vjs-current-time,
.video-js .vjs-time-divider,
.video-js .vjs-duration {
  display: block;
} */

/* .video-js .vjs-time-control,
.vjs-current-time {
  display: block;
}
.video-js .vjs-remaining-time {
  display: none;
} */

.video-js .vjs-current-time,
.video-js .vjs-time-divider,
.video-js .vjs-duration {
  display: block !important;
}

.video-js .vjs-time-control {
  min-width: 0em !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}
